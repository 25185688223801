.file-dropzone {
    min-height: 0 !important;
    padding: 5px;
}

.preview-container {
    position: relative;
    margin-top: 20px;
    display:flex;
    flex-direction: column;
    & img,video {
        width: 150px;
        height: auto;
    }

    & .delete-button {
        position: absolute;
        top: -15px;
        left: 120px;
        background-color:#E9EBFF;
        height: 45px;
        width: 45px;
        padding: 10px;
        border-radius: 45px;
        cursor: pointer;

        & svg {
            transition: all 0.5s ease-in-out;
            transform: scale(0.9);
        }

        &:hover {
            & svg {
                transition: all 0.5s ease-in-out;
                transform: scale(1.0);
            }
        }
    }
}
