.statusButton{
    background-color: grey;
    color: white;
    padding: 0px 20px;
    border-radius: 5px;
    height: 35px;
    justify-content: center;
    align-items: center;
    display: flex;
    transition-property: all;
    transition-duration: 0.3s;
}

.statusButton:hover{
    cursor: pointer;
}

.activeButton{
    background-color: #049e44;
}
.activeButton:hover{
    background-color: #02682d;
}

.disableButton{
    background-color: rgb(163, 39, 1);
}

.disableButton:hover{
    background-color: rgb(105, 10, 10);
}