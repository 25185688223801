.stats {
    margin-top: 20px;
}

.content{
    max-height: 150px;
    overflow: auto;
}

.title {
    margin-bottom: 10px;
    font-size: 1.1rem;
    font-weight: bold;
}