.container{
    background-color: #d9d9d9;
    margin: 10px;
    border-radius: 15px;
    font-size: 17px;
    display: flex;
}

.status{
    background-color: gray;
    padding: 5px 20px;
    border-radius: 10px;
    color: white;
    margin: 5px;
    font-size: 15px;
    font-weight: bold;
    width: 150px;
    text-align: center;
}

.name{
    font-weight: bold;
    width: 250px;
    font-size: 18px;
    margin-top: 8px;
    margin-left: 20px;
}

.iconContainer{
    margin-top: 13px;
    display: flex;
    margin-left: 20px;
    width: 100px;
}

.icon {
    height: 20px;
    width: 20px;
}

.iconText{
    font-weight: bold;
    color: rgb(88, 88, 88);
    margin-top: -2px;
    margin-left: 5px;
}