.inline-icon{
    transform: scale(0.9);

    &.animated {
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            transition: all 0.3s ease-in-out;
            transform: scale(1.05);
        }
    }
}

.upper-top-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    top: 10px;
}
